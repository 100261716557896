<template>
  <div id="reco_mix_match">
    <div v-if="showItems">
      <product-list-elysium v-if="useElysium" v-bind="productListElysiumProps" @productClick="handleProductClick" />

      <product-list v-else class="qa-homepage-panel-recommendation" v-bind="productListProps" />
    </div>
  </div>
</template>

<script>
import api from 'shared_js/api';
import Tracker from 'lib/tracker';
import { sendCompleteTimeMetrics, sendErrorLogMetrics } from 'lib/load-time-metrics';

const ProductList = () =>
  import(/* webpackChunkName: 'product-list' */ 'shared_js/components/ProductList').then((c) => c.default);
const ProductListElysium = () =>
  import(/* webpackChunkName: 'product-list-elysium' */ 'shared_js/components/elysium/ProductListV0');

const evnTrackObj = {
  'product-detail': 'view_reco_detail',
  favorite: 'view_reco_favorite',
  cart: 'view_reco_cart',
  confirmation: 'view_reco_confirmation',
};
const pageLocationObj = {
  'product-detail': 'reco-product-detail',
  favorite: 'reco-favorit',
  cart: 'reco-cart',
  home: 'reco-homepage',
};
const listVariantMap = {
  favorite: 'panel',
};
const PRODUCT_ID36 = document.getElementById('data-reco').dataset.productId;
const ELYSIUM_VERSION = Number(document.getElementById('data-reco').dataset.elysiumVersion);
const EXPERIMENT_NEW_SECTION_IN_PDP = 'PROM-3541_DWEB_New_Section_in_PDP';
const TITLE = document.getElementById('data-reco').dataset.title;
let FROM = document.getElementById('data-reco').dataset.from;

export default {
  name: 'MixAndMatch',
  components: {
    ProductList,
    ProductListElysium,
  },
  data() {
    return {
      currentProduct: PRODUCT_ID36,
      contentWindow: FROM === 'confirmation' ? 4 : 6,
      useElysium: global.APP_SHELL === 'westeros' && ELYSIUM_VERSION === 0,
      isLoading: true,
      loadFail: false,
      products: [],
      scrollerClass: FROM === 'confirmation' ? 'u-3of12' : 'u-2of12',
      scrollOffset: 2,
      title: TITLE || 'Rekomendasi Buat Kamu',
      trigger: null,
      loadTimeMetrics: {
        start: 0,
        end: 0,
        sent: false,
      },
    };
  },

  computed: {
    evnTrack() {
      return evnTrackObj[FROM] || '';
    },
    pageLocation() {
      return pageLocationObj[FROM] || '';
    },
    user() {
      let id;
      let id36;

      // Use momship if available
      if (window.blGlobal && window.blGlobal.user) {
        id = window.blGlobal.user.id || '';
        id36 = window.blGlobal.user.id_36 || '';
      }

      // Use westeros if available
      if (window.USER) {
        id = window.USER.id || '';
        id36 = window.USER.id36 || '';
      }

      return { id, id36 };
    },
    dataTracker() {
      if (this.evnTrack.length > 0) {
        const productsId = [];
        const recoType = [];
        this.products.forEach((el) => {
          productsId.push(el.product.id);
          recoType.push(el.source);
        });

        const dataTracker = {
          evn: this.evnTrack,
          platform: 'desktop_web',
          product_ids: JSON.stringify(productsId),
          reco_types: JSON.stringify(recoType),
          product_id_trigger: this.trigger ? this.trigger.product.id : '',
          strategy: this.trigger ? this.trigger.source : '',
          t: Math.round(new Date().getTime() / 1000),
          ui: this.user.id36,
        };
        return dataTracker;
      }
      return null;
    },
    pixelTracker() {
      if (this.dataTracker) {
        const productIds = JSON.parse(this.dataTracker.product_ids);
        const tempIds = [];
        tempIds.push(productIds.slice(0, this.contentWindow));
        for (let i = this.contentWindow; i < productIds.length; i += this.scrollOffset) {
          tempIds.push(productIds.slice(i, i + this.scrollOffset));
        }
        const trackerGroup = tempIds.map((el, index) => ({
          ...this.dataTracker,
          product_id_views: JSON.stringify(el),
          tracked: index === 0,
        }));

        return trackerGroup;
      }
      return null;
    },

    productListProps() {
      return {
        augmentedProductUrlParams: this.augmentedProductUrlParams,
        contentWindow: this.contentWindow,
        title: this.title,
        handleProductClick: this.handleProductClick,
        handleScroll: this.handleScroll,
        products: this.flatProducts(),
        scrollerClass: this.scrollerClass,
        scrollOffset: this.scrollOffset,
      };
    },

    productListElysiumProps() {
      return {
        augmentedProductUrlParams: this.augmentedProductUrlParams,
        title: this.title,
        products: this.flatProducts(),
        handleNavigation: this.handleScroll,
        variant: listVariantMap[FROM] || FROM,
        contentWindow: this.contentWindow,
      };
    },

    showItems() {
      return !this.isLoading && this.products.length >= this.contentWindow;
    },
  },

  watch: {
    products() {
      if (this.products.length >= 6) {
        let active = false;

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            if (!active && entry.intersectionRatio > 0) {
              this.onVisible();
              active = true;
              observer.unobserve(entry.target);
            }
          });
        };

        const observer = new IntersectionObserver(callback);

        this.$nextTick(() => {
          const el = document.getElementById('reco_mix_match');
          observer.observe(el);
        });
      }
    },

    showItems(val) {
      if (val) {
        const event = new CustomEvent('reco-loaded');
        document.dispatchEvent(event);
      }
    },
  },

  created() {
    FROM = document.getElementById('data-reco').dataset.from;
    this.loadTimeMetrics.start = performance.now();
  },

  mounted() {
    this.getProducts();
    /**
     * 'pdpEventBus' is provided by Elysium
     * 'changeProduct' -  event on click any product
     * 'popChangeProduct' - event onpopstate
     */
    if (global.pdpEventBus) {
      ['changeProduct', 'popChangeProduct'].forEach((ev) => {
        global.pdpEventBus.subscribe(ev, this.handleChangeProduct);
      });
    }
  },

  beforeDestroy() {
    if (global.pdpEventBus) {
      ['changeProduct', 'popChangeProduct'].forEach((ev) => {
        global.pdpEventBus.unsubscribe(ev, this.handleChangeProduct);
      });
    }
  },

  updated() {
    if (!this.loadTimeMetrics.sent && !this.loadFail) {
      this.loadTimeMetrics.end = performance.now();
      const duration = this.loadTimeMetrics.end - this.loadTimeMetrics.start;
      // Metrics must be an array
      const metrics = [this.generateCompleteTimeHistogram(duration)];
      sendCompleteTimeMetrics(metrics);
      this.loadTimeMetrics.sent = true;
    }
  },

  methods: {
    getProducts() {
      this.isLoading = true;
      api
        .getMNM(this.currentProduct, FROM)
        .then((response) => {
          this.products = response.data;
          if (response.meta.seed.product !== null && response.meta.seed.product.id) {
            this.trigger = response.meta.seed;
            this.isLoading = false;
          }
        })
        .catch((response) => {
          this.sendErrorLog(response);
          this.loadFail = true;
        });
    },
    onVisible() {
      this.sendPixelTracker(this.pixelTracker[0]);
      this.$set(this.pixelTracker[0], 'tracked', true);
    },
    handleScroll(index) {
      if (!this.pixelTracker[index].tracked) {
        this.sendPixelTracker(this.pixelTracker[index]);
        this.$set(this.pixelTracker[index], 'tracked', true);
      }
    },
    sendPixelTracker(pixelTracker) {
      const data = Object.assign({}, pixelTracker);
      delete data.tracked;
      Tracker.sendPixel(data);
    },
    augmentedProductUrlParams(contentType) {
      const params = {};

      params.from = FROM;
      params.section = 'reco';
      params.content_type = contentType;

      return params;
    },
    handleChangeProduct(e) {
      this.currentProduct = e.detail.product.id;
      this.getProducts();
    },
    handleProductClick(prod) {
      if (this.dataTracker) {
        const dataTracker = JSON.parse(JSON.stringify(this.dataTracker));
        const recoTypes = JSON.parse(dataTracker.reco_types);
        dataTracker.referrer = FROM.replace('-', '_');
        dataTracker.source = FROM.replace('-', '_');
        dataTracker.section = 'reco';
        dataTracker.product_id = prod.id;
        delete dataTracker.product_id_views;
        dataTracker.evn = 'view_product';
        dataTracker.t = Math.round(new Date().getTime() / 1000);
        dataTracker.reco_type = recoTypes[this.products.findIndex((p) => p.product.id === prod.id)];
        Tracker.event(dataTracker);
      }

      if (FROM === 'product-detail') {
        const experimentParams = {
          experimentName: EXPERIMENT_NEW_SECTION_IN_PDP,
          participant: this.user.id,
          data: {
            metric: 'click_rekomendasi_buat_kamu',
            value: 1,
          },
        };
        api.scoreMetric(experimentParams);

        // PDF Prambanan Experiment
        api.scoreMetric({
          experimentName: 'PDF-915_DWEB_pdp_revamp_prambanan',
          participant: this.user.id,
          data: {
            metric: 'click_rekomendasi_buat_kamu',
            value: 1,
          },
        });
        // End Of PDF Prambanan Experiment
      }
    },
    sendErrorLog(err) {
      let errorMessage = err.message;
      const errorTag = ['DWeb', 'reco'];

      if (this.pageLocation === 'reco-product-detail') {
        const productId = parseInt(this.currentProduct, 36);
        errorMessage = errorMessage.concat(':::', productId);
        errorTag.push('product_detail');
      }

      sendErrorLogMetrics({ errorTag, errorMessage });
    },
    generateCompleteTimeHistogram(duration) {
      const MILI_SECONDS = 1000;
      const hostName = 'complete_time_reco_desktop';
      const { pageLocation } = this;
      return {
        name: hostName,
        kind: 'h',
        value: duration / MILI_SECONDS,
        labels: {
          reco: pageLocation,
          platform: window.navigator.userAgent,
          status_code: '200',
        },
      };
    },

    // flat product property as same level as reference_type and source
    flatProducts() {
      return this.products.map((product) => ({
        ...product.product,
        reference_type: product.reference_type,
        source: product.source,
      }));
    },
  },

};
</script>
