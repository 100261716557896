// IDENTITY - from Westeros' global
const { fetch, location, blGlobal, IDENTITY } = window;

// See: https://gist.github.com/LeverOne/1308368 to generate RFC 4122 compliant UUID
/* eslint-disable */
const UUID = function(a, b) {
  for (
    b = a = '';
    a++ < 36;
    b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : '-'
  );
  return b;
};
/* eslint-enable */

const IS_MWEB = location.origin.indexOf('://m.') !== -1;
const BASE_ATTACHE_URL = 'https://attache.bukalapak.com';
const ATTACHE_ENDPOINT = IS_MWEB ? `${BASE_ATTACHE_URL}/samples/mobile-web` : `${BASE_ATTACHE_URL}/samples/desktop-web`;
const ATTACHE_ERROR_LOG_ENDPOINT = IS_MWEB
  ? `${BASE_ATTACHE_URL}/logs/mobile-web`
  : `${BASE_ATTACHE_URL}/logs/desktop-web`;

const sendPostRequest = (requestUrl, payload) =>
  new Promise((resolve, reject) => {
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain; charset=utf-8',
      },
      body: JSON.stringify(payload),
    })
      .then(() => resolve())
      .catch(err => {
        const error = new Error(`Error sending log to ${requestUrl} : ${err.message}`);
        reject(error);
      });
  });

/**
 * @params number duration
 *     duration of complete time in miliseconds
 */
export const sendCompleteTimeMetrics = metrics => {
  sendPostRequest(ATTACHE_ENDPOINT, metrics).catch(err => {
    console.error(`Complete Time Metrics Error -- ${err.message}`);
  });
};

export const sendErrorLogMetrics = ({ errorTag, errorMessage }) => {
  const identity = IDENTITY || (blGlobal && blGlobal.user && blGlobal.user.identity);
  const errorLog = [
    {
      request_id: UUID(),
      tags: errorTag,
      message: errorMessage,
      severity: 'WARNING',
      session_id: identity,
    },
  ];

  sendPostRequest(ATTACHE_ERROR_LOG_ENDPOINT, errorLog).catch(err => {
    console.error(`Error sending error log -- ${err.message}`);
  });
};
