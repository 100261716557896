import MixAndMatch from './MixAndMatch.vue';

const { Vue } = window;
const initializeMixAndMatch = () => {
  const APP_ID = '#mnm';

  const app = new Vue({
    name: 'MixAndMatchRoot',
    render: h => h(MixAndMatch),
  });

  app.$mount(APP_ID);
};

initializeMixAndMatch();
